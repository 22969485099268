<template>
  <div class="register_page">
    <!-- 切换语言 -->
    <div style="position: absolute; top: 20px; right: 55px">
      <el-dropdown @command="handlanguage">
        <span class="el-dropdown-link" style="width: 112px; display: inline-block">
          {{ language.language }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="CHS">简体中文</el-dropdown-item>
          <el-dropdown-item command="TC">繁體中文</el-dropdown-item>
          <el-dropdown-item command="EN">English</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="register_title">
      <div v-if="!showtitle">{{ language.logo }}</div>
      <div v-if="showtitle">{{ language.logo }}{{ language.title2 }}</div>
    </div>
    <!-- 注册框 -->
    <div class="register_box" v-if="type">
      <div style="text-align: center; font-weight: 600; font-size: 24px">
        {{ language.RegisteredAccount }}
      </div>
      <!-- 邮箱 -->
      <div style="margin-top: 20px; width: 300px">
        <div>{{ language.Email }}</div>
        <el-input
          class="register_input"
          v-model="Email"
          type="text"
          :placeholder="language.Email"
        ></el-input>
      </div>
      <!-- 邮箱验证码 -->
      <div style="margin-top: 20px; width: 300px">
        <div>{{ language.VerificationCode }}</div>
        <div style="display: flex; margin-top: 10px">
          <el-input
            class="register_input"
            style="margin-top: 0"
            v-model="VerificationCode"
            type="text"
            :placeholder="language.VerificationCode"
          ></el-input>
          <el-button style="height: 100%" v-if="time2" type="info">{{ time2 }}</el-button>
          <el-button
            style="height: 100%"
            v-if="!time2"
            type="primary"
            @click="Send_code()"
            >{{ language.Send_code }}</el-button
          >
        </div>
      </div>
      <div style="color: red; font-size: 11px">
        {{ language.NoteChecktheinboxortrash }}
      </div>
      <!-- 密码 -->
      <div style="margin-top: 20px; width: 300px; position: relative">
        <div>{{ language.Password }}</div>
        <!-- <el-input class="register_input" type="text" v-model="Password" :placeholder="language.Password"></el-input> -->
        <el-input
          style="box-sizing: border-box"
          v-model="Password"
          :type="passwordicon ? 'password' : 'text'"
          :placeholder="language.Password"
        ></el-input>
        <span v-if="passwordicon" style="" @click="eyebtn()" class="iconeye3"></span>
        <span v-else style="" @click="eyebtn()" class="iconeye4"></span>
      </div>
      <!-- 确认密码 -->
      <div style="margin-top: 20px; width: 300px; position: relative">
        <div>{{ language.Confirm_Password }}</div>
        <!-- <el-input class="register_input" type="text" v-model="Confirm_Password" :placeholder="language.Confirm_Password"></el-input> -->
        <el-input
          style="box-sizing: border-box"
          v-model="Confirm_Password"
          :type="passwordicon2 ? 'password' : 'text'"
          :placeholder="language.Confirm_Password"
        ></el-input>
        <span v-if="passwordicon2" style="" @click="eyebtn2()" class="iconeye3"></span>
        <span v-else style="" @click="eyebtn2()" class="iconeye4"></span>
      </div>
      <!-- 协议按钮 -->
      <div style="margin-top: 20px; display: flex; align-items: center">
        <el-checkbox v-model="checked"></el-checkbox>
        <div style="color: #a1a1a1; font-size: 13px; margin-left: 10px">
          {{ language.Ihavereadandagreeto }}
          <span @click="theentryagreement()" style="cursor: pointer; color: orange">{{
            language.theentryagreement
          }}</span>
        </div>
      </div>
      <!-- 注册按钮 -->
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          border: none;
          margin-top: 80px;
        "
      >
        <el-button
          style="
            width: 100%;
            border-radius: 10px;
            box-shadow: 5px 5px 5px rgb(0 0 0 / 35%);
          "
          type="primary"
          @click="Register()"
          >{{ language.Register }}</el-button
        >
      </div>
    </div>
    <!-- 注册成功跳转提示 -->
    <div class="register_box register_success_box" v-if="!type">
      <div style="text-align: center; font-weight: 600; font-size: 24px">
        {{ language.RegisteredAccount }}
      </div>
      <div
        style="
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <div style="text-align: center; max-width: 400px">
          {{ language.AccountIsRegistered }}
        </div>
        <div style="margin-top: 20px" v-if="language.language == '简体中文'">
          {{ time }}{{ language.Jump }}
        </div>
        <div style="margin-top: 20px" v-if="language.language == '繁體中文'">
          {{ time }}{{ language.Jump }}
        </div>
        <div style="margin-top: 20px" v-if="language.language == 'English'">
          {{ language.Jump }} {{ time }} {{ language.Jump2 }}
        </div>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :title="language.EntryAgreement"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <div v-html="settleInExplain"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ language.determine }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import config from "../../api/config";
import { register, emssend, explaingetConfig } from "../../api/index";
export default {
  data() {
    return {
      language: "", //语言
      type: true, //true：注册输入框  false:注册成功提示
      time: 3, //倒计时
      Email: "", //邮箱
      Password: "", //密码
      Confirm_Password: "", //确认密码
      timeopen: "", //计时器方便后续关闭
      time2: "",
      VerificationCode: "", //验证码
      sendtimeInterval: null,
      passwordicon: true, //显示密码
      passwordicon2: true,
      showtitle: true,
      checked: false, //协议勾选
      dialogVisible: false, //协议显示隐藏
      settleInExplain: "", //协议内容
    };
  },
  created() {
    if (config.BaseUrl.indexOf("test") > 0) {
      this.showtitle = true;
    } else {
      this.showtitle = false;
    }
    // 判断语言
    this.changelanguage();
  },
  mounted() {
    this.init();
  },
  watch: {
    // 判断语言
    "$store.state.language"() {
      this.changelanguage();
    },
  },

  methods: {
    // 页面初始，获取协议
    init() {
      explaingetConfig().then((res) => {
        this.settleInExplain = res.data.settleInExplain;
        this.settleInExplain = this.settleInExplain.replace(/&amp;/g, "&");
        this.settleInExplain = this.settleInExplain.replace(/&lt;/g, "<");
        this.settleInExplain = this.settleInExplain.replace(/&gt;/g, ">");
        this.settleInExplain = this.settleInExplain.replace(/&nbsp;/g, " ");
        this.settleInExplain = this.settleInExplain.replace(/&#39;/g, "'");
        this.settleInExplain = this.settleInExplain.replace(/&quot;/g, '"');
      });
    },
    //密码隐藏及显示
    eyebtn() {
      this.passwordicon = !this.passwordicon;
    },
    eyebtn2() {
      this.passwordicon2 = !this.passwordicon2;
    },
    // 注册按钮
    Register() {
      if (!this.Email) {
        //未输入邮箱
        this.$message({
          message: this.language.noEmail,
          type: "warning",
        });
        return;
      }
      if (!this.Password) {
        //未输入密码
        this.$message({
          message: this.language.nopassword,
          type: "warning",
        });
        return;
      }
      if (!this.Confirm_Password) {
        //未输入确认密码
        this.$message({
          message: this.language.noConfirm_Password,
          type: "warning",
        });
        return;
      }
      if (!this.VerificationCode) {
        this.$message({
          message: this.language.noVerificationCode,
          type: "warning",
        });
        return;
      }
      if (!this.checked) {
        this.$message({
          message: this.language.Pleasereadandagreetotheentryagreement,
          type: "warning",
        });
        return;
      }
      let data = {
        username: this.Email,
        password: this.Password,
        email: this.Email,
        captcha: this.VerificationCode,
      };
      register(data).then((res) => {
        if (res.code == 1) {
          this.settimeInterval();
          this.type = false;
        }
      });
      // console.log(data)
      // this.settimeInterval()
      // this.type = false
    },
    // 打开协议
    theentryagreement() {
      this.dialogVisible = true;
    },
    // 修改成功倒计时跳转
    settimeInterval() {
      let that = this;
      that.time = 3;
      this.timeopen = setInterval(() => {
        that.time = that.time - 1;
        if (that.time == 0) {
          this.$router.push("/login");
          clearInterval(that.timeopen);
        }
      }, 1000);
    },
    // 发送验证码
    Send_code() {
      if (!this.Email) {
        //未输入邮箱
        this.$message({
          message: this.language.noEmail,
          type: "warning",
        });
        return;
      }
      let data = {
        email: this.Email,
        event: "register",
      };
      this.timeInterval();
      emssend(data).then((res) => {
        if (res.code == 1) {
          // this.timeInterval();
        }
      });
      // this.timeInterval()
    },
    // 验证码倒计时
    timeInterval() {
      let that = this;
      that.time2 = 60;
      this.sendtimeInterval = setInterval(() => {
        that.time2 = that.time2 - 1;
        if (that.time2 == 0) {
          clearInterval(that.sendtimeInterval);
        }
      }, 1000);
    },
    // 获取语言
    changelanguage() {
      this.language = this.$units();
    },
    // 切换语言
    handlanguage(command) {
      if (command == "CHS") {
        this.language = "简体中文";
      } else if (command == "TC") {
        this.language = "繁體中文";
      } else if (command == "EN") {
        this.language = "English";
      }
      this.$store.commit("changelanguage", command);
      this.changelanguage();
      // this.$message('click on item ' + command);
    },
  },
  destroyed() {
    // 页面销毁取消计时器
    clearInterval(this.timeopen);
  },
};
</script>
<style scoped>
.register_page {
  width: 100%;
  height: 100%;
  background-image: url("../../static/loginpagebg.png");
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.register_box {
  background: #fff;
  border: 1px solid #efefef;
  padding: 20px 100px 50px 100px;
}
.register_title {
  font-family: SourceHanSansCN-Regular;
  font-size: 30px;
  font-weight: 400;
  font-stretch: normal;
  letter-spacing: 0;
  color: #fff;
  line-height: 117px;
  text-align: center;
}
.register_input {
  margin-top: 10px;
  /* width: 300px; */
}
.register_success_box {
  width: 502px;
  height: 496px;
  padding: 0 !important;
  padding-top: 20px !important;
  display: flex;
  flex-direction: column;
}
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}

.el-icon-arrow-down {
  font-size: 12px;
}
.iconeye3 {
  display: inline-block;
  width: 33px;
  height: 22px;
  position: absolute;
  z-index: 9;
  top: 27px;
  cursor: pointer;
  right: 0;
  background: url("../../static/icon-login.png") no-repeat 0 -43px;
}

.iconeye4 {
  display: inline-block;
  width: 33px;
  height: 22px;
  position: absolute;
  z-index: 9;
  top: 27px;
  cursor: pointer;
  right: 0;
  background: url("../../static/icon-login.png") no-repeat -55px -43px;
}
</style>
